// Values order is important as it is read by Object.values and passed as table headers.
// Changing order will change order of related table headers
export enum LoanApplicationTableHeaders {
  BRANCH_NAME = 'BRANCH_NAME',
  REFERENCE_NO = 'REFERENCE_NO',
  CIF = 'CIF',
  APPLICANT_NAME = 'APPLICANT_NAME',
  PRODUCT_NAME = 'PRODUCT_NAME',
  PRINCIPAL_AMOUNT = 'PRINCIPAL_AMOUNT',
  INTEREST_RATE = 'INTEREST_RATE',
  ASSIGNED_TO = 'ASSIGNED_TO',
  STATUS = 'STATUS',
  ACTIONS_COLUMN = ''
}

enum LoanApplicationCheckBoxTableHeader {
  CHECK_BOX_COLUMN = ''
}

export const LoanApplicationWithCheckBoxTableHeaders = {
  ...LoanApplicationCheckBoxTableHeader,
  ...LoanApplicationTableHeaders
};

export enum LoanApplicationLabels {
  PAYMENT_INTERVAL = 'PAYMENT_INTERVAL',
  TOTAL_AMORTIZATION_NUMBER = 'TOTAL_AMORTIZATION_NUMBER',
  CREATED_BY = 'CREATED_BY',
  MODIFIED_BY = 'MODIFIED_BY',
  MODIFIED_ON = 'MODIFIED_ON',
  CREATED_ON = 'CREATED_ON',
  APPROVAL_STATUS = 'APPROVAL_STATUS',
  CREATION_TYPE = 'CREATION_TYPE'
}

export enum ConsolidatedLoansTableHeaders {
  NUMBER = 'NUMBER',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  PRODUCT_NUMBER = 'PRODUCT_NUMBER',
  PRINCIPAL = 'PRINCIPAL',
  INTEREST = 'INTEREST',
  CBU = 'CBU',
  PF = 'PF',
  TP = 'TP',
  PAST_DUE_INTEREST = 'PAST_DUE_INTEREST',
  PENALTIES = 'PENALTIES',
  CUSTOM_FEES = 'CUSTOM_FEES',
  ACTIONS_COLUMN = ''
}

export enum CustomerCbsLoansTableHeaders {
  NUMBER = 'NUMBER',
  PRODUCT_NAME = 'PRODUCT_NAME',
  PRODUCT_NUMBER = 'PRODUCT_NUMBER',
  STATUS = 'STATUS',
  DAYS_LATE = 'DAYS_LATE',
  PAYMENT_INTERVAL = 'PAYMENT_INTERVAL',
  MATURITY_DATE = 'MATURITY_DATE',
  TOTAL_AMORTIZATION_NUMBER = 'TOTAL_AMORTIZATION_NUMBER',
  AVERAGE_AMORTIZATION_AMOUNT_MONTHLY = 'AVERAGE_AMORTIZATION_AMOUNT_MONTHLY',
  OUTSTANDING_PRINCIPAL = 'OUTSTANDING_PRINCIPAL',
  OUTSTANDING_INTEREST = 'OUTSTANDING_INTEREST',
  OUTSTANDING_CBU = 'OUTSTANDING_CBU',
  OUTSTANDING_PF = 'OUTSTANDING_PF',
  OUTSTANDING_TP = 'OUTSTANDING_TP',
  OUTSTANDING_PAST_DUE_INTEREST = 'OUTSTANDING_PAST_DUE_INTEREST',
  OUTSTANDING_PENALTIES = 'OUTSTANDING_PENALTIES',
  OUTSTANDING_CUSTOM_FEES = 'OUTSTANDING_CUSTOM_FEES',
  ACTIONS_COLUMN = ''
}